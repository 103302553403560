import React, { useCallback, useEffect, useRef, useState, memo } from 'react';

import { Box, Button } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import styled from '@emotion/styled';

import { Details } from '../../common/Details/Details';

import { ChatHeaderStyled } from '../../components/Chat/ChatHeader';
import { FileUploader } from '../../components/FileUploader/FileUploader';
import { translates } from '../../constants/translates';

import { ReactComponent as ClipIcon } from '../../assets/icons/clip.svg';
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg';
import { ReactComponent as RectangleIcon } from '../../assets/icons/rectangle.svg';

import { ReactComponent as ViberIcon } from '../../assets/icons/socials/viber.svg';
import { ReactComponent as TelegramIcon } from '../../assets/icons/socials/telegram.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/socials/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/socials/instagram.svg';

import { socket } from '../../index';

const chatFooterStyles = {
  padding: '0 !important',
  height: '34px!important',
  justifyContent: 'center',
  alignItems: 'center',
};

const Textarea = styled(TextareaAutosize)(({ theme, isMine, sx }) => ({
  border: 'none',
  width: '100%',
  resize: 'none',
  outline: 'none',
  fontSize: '14px',
  fontFamily: theme.typography.fontFamily,
  '&[disabled]': {
    cursor: 'default',
    backgroundColor: 'initial',
  },
}));

const TYPING_TIMER = 3000;
const MAX_TEXT_LENGTH = 500;

export const ChatFooter = ({
  dialogId,
  sendMessage,
  messages,
  defaultLang,
  channels,
  visitorMessages,
  isDialogActive,
  isInSchedule,
  showSocials,
  sendMessageWithActiveDialog,
  copyDisabled = 1,
  isChatActive = false,
}) => {
  const textareaRef = useRef(null);
  const inputFileRef = useRef(null);
  const [messageText, setMessageText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isWriting, setIsWriting] = useState(false);

  const messageTextHandler = useCallback(
    (event) => {
      const { value } = event.target;
      if (value.length > MAX_TEXT_LENGTH) return;
      setMessageText(value);

      if (value && dialogId && !isWriting) {
        socket.emit('dialog.printing.send', {
          status: 'start',
          id: dialogId,
        });
      }
      setIsFocused(true);
      setIsWriting(true);
    },
    [dialogId, isWriting]
  );

  const addFileHandler = useCallback(() => {
    inputFileRef.current.click();
  }, []);

  const sendMessageHandler = useCallback(
    (id) => {
      sendMessage(messageText, id);
      setMessageText('');
    },
    [messageText]
  );

  const sendMessageWithActiveDialogHandler = useCallback(
    () => sendMessageWithActiveDialog(sendMessageHandler),
    [messageText]
  );

  useEffect(() => {
    const sendByCtrlEnter = (e) => {
      if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
        e.preventDefault();
        sendMessageWithActiveDialog(sendMessageHandler);
      } else if (e.key === 'Enter' && e.ctrlKey) {
        e.preventDefault();

        setMessageText((prev) => {
          const selected = textareaRef.current.selectionStart;
          const textBefore = prev.substring(0, selected);
          const textAfter = prev.substring(selected);
          const text = `${textBefore}\n${textAfter}`;

          setTimeout(() => {
            textareaRef.current.selectionEnd = selected + 1;
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
          }, 0);
          return text;
        });
      }
    };
    document.addEventListener('keydown', sendByCtrlEnter);

    return () => {
      document.removeEventListener('keydown', sendByCtrlEnter);
    };
  }, [messageText]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isWriting) {
        setIsWriting(false);
        socket.emit('dialog.printing.send', {
          status: 'end',
          id: dialogId,
        });
      }
    }, TYPING_TIMER);

    const clickHandler = (e) => {
      if (textareaRef.current === e.target) {
        setIsFocused(true);
      } else {
        if (isWriting) {
          socket.emit('dialog.printing.send', {
            status: 'end',
            id: dialogId,
          });
          setIsFocused(false);
          setIsWriting(false);
        }
      }
    };
    document.addEventListener('click', clickHandler);
    return () => {
      setTimeout(timer);
      document.removeEventListener('click', clickHandler);
    };
  }, [isFocused, isWriting]);

  const callbackTextareaRef = useCallback((node) => {
    textareaRef.current = node;
    node?.focus();
  }, []);

  return (
    <Box>
      {visitorMessages &&
        // isInSchedule &&
        // !messages?.length &&
        // !isDialogActive &&
        !!channels?.length && (
          // 1 && (
          <Details title={translates[defaultLang].write_us_in_messenger}>
            <Messengers messengers={channels} />
          </Details>
        )}
      {!isChatActive && (
        <>
          <FileUploader ref={inputFileRef} dialogId={dialogId} />
          <Box
            sx={{
              pt: 1.4,
              px: 2,
              pb: 1.45,
              display: 'flex',
              height: '83px',
              borderTop: '1px solid',
              borderTopColor: 'grey.light',
            }}
          >
            <Textarea
              ref={callbackTextareaRef}
              value={messageText}
              minRows={2}
              maxRows={3}
              onChange={messageTextHandler}
              placeholder={translates[defaultLang].your_message}
              disabled={isChatActive}
              style={{
                backgroundColor: 'transparent',
                paddingRight: '50px',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                right: '54px',
                bottom: '83px',
                color: 'grey.derken',
              }}
            >
              {messageText.length}/{MAX_TEXT_LENGTH}
            </Box>
            <Box
              component={'form'}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
                paddingLeft: '8px',
              }}
            >
              <ChatButton
                disabled={!isChatActive && !dialogId}
                onClick={addFileHandler}
              >
                <ClipIcon />
              </ChatButton>
              <ChatButton
                disabled={isChatActive || !messageText.trim().length}
                onClick={sendMessageWithActiveDialogHandler}
              >
                <SendIcon />
              </ChatButton>
            </Box>
          </Box>
        </>
      )}
      <ChatHeaderStyled sx={chatFooterStyles}>
        {!copyDisabled && <>{translates[defaultLang].footer_text}</>}
      </ChatHeaderStyled>
    </Box>
  );
};

const ChatButton = memo(
  ({ children, disabled = false, onClick = () => {}, ...props }) => {
    const buttonStyles = {
      cursor: !disabled ? 'pointer' : 'default',
      opacity: !disabled ? '1' : '0.2',
      backgroundColor: 'transparent',
      '&:hover': {
        opacity: !disabled ? '0.8' : '0.2',
        backgroundColor: 'transparent',
      },
      '&.MuiButton-root': {
        minWidth: '0',
        padding: '0',
      },
      '& svg path': {
        color: 'header.main',
        fill: 'currentColor',
      },
    };

    return (
      <Button
        onClick={onClick}
        disabled={disabled}
        sx={buttonStyles}
        {...props}
      >
        {children}
      </Button>
    );
  }
);

const getIcon = (icon) => {
  switch (icon) {
    case 'viber':
      return ViberIcon;
    case 'telegram':
      return TelegramIcon;
    case 'facebook':
      return FacebookIcon;
    case 'instagram':
      return InstagramIcon;
    default:
      return null;
  }
};

const Messengers = ({
  messengers = [
    {
      type: 'Viber',
      name: 'viber://chat?number=+380123456789',
    },
    {
      type: 'Telegram',
      name: 'https://t.me/username',
    },
    {
      type: 'Facebook',
      name: 'https://www.facebook.com/username',
    },
    {
      type: 'Instagram',
      name: 'https://www.instagram.com/username',
    },
  ],
}) => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px 28px',
      alignItems: 'center',
      maxHeight: '200px',
      overflowY: 'auto',
    }}
  >
    {messengers.map((messenger) => (
      //  <Tooltip placement="top" title={`Contact with as in ${messenger.name}`}>
      <Box
        component={'a'}
        sx={{
          display: 'flex',
        }}
        key={messenger.type}
        href={messenger.name}
        target="_blank"
        rel="noreferrer"
      >
        <Box
          title={messenger.type[0].toUpperCase() + messenger.type.slice(1)}
          component={getIcon(messenger.type)}
          sx={{
            width: '26px',
            height: '26px',
            cursor: 'pointer',
          }}
        />
      </Box>
      //  </Tooltip>
    ))}
  </Box>
);

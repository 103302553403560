import React from 'react';
import {Box, Typography, Button, Stack} from '@mui/material';

import {translates} from '../../constants/translates';

export const Dialog = ({
                           defaultLang,
                           title,
                           onSend,
                           onCancel,
                           children,
                           component,
                           applyText = translates[defaultLang].send,
                           cancelText = translates[defaultLang].cancel,
                           ...props
                       }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            p: '50px',
        }}
    >
        <Stack
            sx={{
                width: '100%',
                textAlign: 'center',
            }}
            spacing={2.9}
            component={component}
            {...props}
        >
            <Typography sx={(theme) => ({fontFamily: theme.typography.fontFamily})} typography="h2">{title}</Typography>
            {children && <Box>{children}</Box>}
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '15px 20px',
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    type="submit"
                    onClick={onSend}
                    variant="contained"
                    sx={(theme) => ({
                        flex: 1,
                        minWidth: '120px',
                        color: 'wtite.main',
                        fontFamily: theme.typography.fontFamily
                    })}
                >
                    {applyText}
                </Button>
                <Button
                    onClick={onCancel}
                    variant="contained"
                    color="grey"
                    sx={(theme) => ({
                        flex: 1,
                        minWidth: '120px',
                        color: 'grey.dark',
                        fontFamily: theme.typography.fontFamily
                    })}
                >
                    {cancelText}
                </Button>
            </Box>
        </Stack>
    </Box>
);

import { Avatar, Badge } from '@mui/material';

import { stringAvatar } from '../../utils';

const sizes = {
  xs: 24,
  sm: 32,
  md: 36,
  lg: 48,
};

export const User = ({ src, alt, sx, size = 'md',  isOnline = false }) => {
  const sizesSx = {
    width: sizes[size],
    height: sizes[size],
    ...sx,
  };

  return (
    <Badge
      overlap="circular"
      color="primary"
      variant="dot"
      invisible={!isOnline}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Avatar
        alt={alt}
        src={src}
        sx={{ ...sizesSx }}
        {...(src
          ? {}
          : stringAvatar(alt, { ...sizesSx, fontSize: sizes[size] / 2.5 }))}
      />
    </Badge>
  );
};
